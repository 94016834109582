import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useDidMountEffect } from '../../../../../../../../../utilities/hook';
import HouseService from '../../../../../../../../../service/House';
import Toast from '../../../../../../../../components/common/Toast';
const Refresh = '';
const HeartIcon = '';
const Close = '';
const Logo = '';
const HousePrice = ({
  onDesignList,
  setOnDesignList,
  landInfo,
  title,
  currentHouse,
  setCurrentHouse,
  setPyeongValue,
  setMinPyeongValue,
  setSelectedDesignFloor,
  setSelectedDesignIdx,
  type5,
  selectCunsult,
  setSelectCunsult,
  setHouseSelected,
  houseSelected,
  setSelectedPyeong,
  setSelectedStdBuildingToLandRatio,
  address,
  setLandPyeong,
}: any) => {
  const [orderBy, setOrderBy] = useState('');
  const [totalList, setTotalList] = useState(41);
  const [disignList, setDisignList] = useState([
    {
      designIdx: 34,
      mainImg: 'houseDesign/rural/농림-22-37-가/image1.png',
      designName: '[2022] 농림-22-37-가',
      totalArea: 121.23,
      floor: 2,
      structure: 'C',
      pyeong: 36.67,
    },
  ]);
  const [limit, setLimit] = useState(3);
  const [page, setPage] = useState(0);
  const [onToast, setOnToast] = useState(false);
  const [areaType, setAreaType] = useState('P');
  const [onDetail, setOnDetail] = useState(false);

  useEffect(() => {
    const getList = async () => {
      try {
        const { result } = await HouseService.list(
          limit,
          limit * page,
          orderBy,
        );
        if (limit === 10 && page !== 0) {
          setDisignList([...disignList, ...result.list.rows]);
        } else {
          setDisignList(result.list.rows);
        }
        setTotalList(result.list.totalCount);
      } catch (err) {
        console.log(err);
      }
    };
    getList();
  }, [page, limit]);

  useDidMountEffect(() => {
    const getList = async () => {
      try {
        const { result } = await HouseService.list(
          disignList.length,
          0,
          orderBy,
        );

        setDisignList(result.list.rows);
        setTotalList(result.list.totalCount);
      } catch (err) {
        console.log(err);
      }
    };
    getList();
  }, [orderBy]);

  const viewDesign = async (designIdx: string) => {
    try {
      const res = await HouseService.view(designIdx, address);
      setCurrentHouse({
        ...res.result,
        designIdx: designIdx,
      });
      setOnDetail(true);
    } catch (err) {
      console.log(err);
    }
  };

  const houseType = {
    A: '목조',
    B: '철근콘크리트',
    C: '철골조',
    D: '조적조',
    E: '기타',
  };

  const download = async (link: string) => {
    try {
      const res =  HouseService.download(link);
      const link1 = document.createElement('a');
      link1.href = res;
      link1.style.display = 'none';
      document.body.appendChild(link1);
      link1.click();
      link1.remove();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    console.log(
      type5 === 'R'
        ? `${Math.round(Number(landInfo.houseArea * 0.3025) * 10) / 10}`
        : `${Math.round(Number(currentHouse.totalArea * 0.3025) * 10) / 10}`,
    );
  }, [currentHouse]);
  const OwnLaborBtn = ({
    btn,
    description,
    state,
    setState,
  }: {
    btn: string;
    description: string;
    state: any;
    setState: any;
  }) => (
    <div
      className={
        state === btn
          ? 'w-50per border-radius-8 padding-12-12-12-8 bg-primary-600 flex-center hover'
          : 'border-radius-8 padding-12-12-12-8 bg-light-gray-200 w-50per flex-center hover'
      }
      onClick={() => {
        setState(btn);
      }}
    >
      <div
        className={
          state === btn
            ? 'font-bold f-size-14 line-h-20 color-white'
            : 'font-medium f-size-14 line-h-20 color-171E2640'
        }
      >
        {description}
      </div>
    </div>
  );

  const TableTitle = ({ title }: any) => (
    <div className="font-bold f-size-16 line-h-24 color-text-primary">
      {title}
    </div>
  );

  const TableSubTitle = ({ title }: any) => (
    <div
      className="font-medium f-size-14 line-h-20 color-text-tertiary nowrap"
      style={{ flex: 0.5 }}
    >
      {title}
    </div>
  );

  const TableItemContent = ({ content }: any) => (
    <div
      className="font-bold f-size-14 line-h-20 color-text-secondary nowrap"
      style={{ flex: 0.5 }}
    >
      {content}
    </div>
  );

  const orderHandler = (str: string) => {
    if (str === 'P') {
      if (orderBy === 'PA') {
        setOrderBy('PD');
      } else if (orderBy === 'PD') {
        setOrderBy('');
      } else {
        setOrderBy('PA');
      }
    } else if (str === 'F') {
      if (orderBy === 'FA') {
        setOrderBy('FD');
      } else if (orderBy === 'FD') {
        setOrderBy('');
      } else {
        setOrderBy('FA');
      }
    }
  };

  useEffect(() => {
    if (onToast) {
      setTimeout(() => {
        setOnToast(false);
      }, 1000);
    }
  }, [onToast]);

  return (
    <div
      style={{
        maxWidth: '720px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        backgroundColor: '#FFFFFF',
        boxShadow:
          '0px 4px 10px rgba( 0.16470588743686676, 0.21568627655506134,   0.27450981736183167,   0.07999999821186066 );',
        overflowX: 'hidden',
        borderRadius: '8px',
      }}
    >
      {onToast && (
        <div
          className="flex-center  "
          style={{
            display: 'flex',
            zIndex: 9999,
            position: 'absolute',
            bottom: '20%',
            left: '50%',
            // transform: 'translate(-50%, -50%)', // 화면 중앙으로 이동
            width: '90',
          }}
        >
          {/* <Toast
            text="설계안이 선택되었습니다."
            bottom={window.innerWidth ? '90' : '90'}
            left={window.innerWidth > 720 ? '465' : '30'}
          /> */}
          <ToastModal>
            <img src="/asset/images/icon/check_box_on.png" alt="" />
            <div>설계안이 선택되었습니다.</div>
          </ToastModal>
        </div>
      )}
      {/* {onPop ? (
        <div className="padding-16 flex-row">
          <div
            className="bg-blue-gray-800 w-100per padding-8 border-radius-8 gap-10 flex-row"
            style={{ opacity: "90%" }}
          >
            <div className="font-bold f-size-14 line-h-20 color-FFFFFF">√</div>
            <div className="font-bold f-size-14 line-h-20 color-FFFFFF">
              견적이 저장되었습니다.
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {onDesignList ? (
        <>
          {newFunction(
            setOnDesignList,
            setLimit,
            setPage,
            onDetail,
            setOnDetail,
            download,
            currentHouse,
            totalList,
            orderBy,
            orderHandler,
            disignList,
            viewDesign,
            houseType,
            TableSubTitle,
            TableItemContent,
            page,
            type5,
            landInfo,
            areaType,
            setAreaType,
          )}
        </>
      ) : (
        ''
      )}

      {onDetail ? (
        window.innerWidth ? (
          <div
            className="fixed w-100per bg-FFFFFF of-y-auto"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              zIndex: 400,
              maxWidth: '720px',
            }}
          >
            {newFunction2(
              setSelectedStdBuildingToLandRatio,
              setSelectedPyeong,
              setSelectedDesignFloor,
              setSelectedDesignIdx,
              setMinPyeongValue,
              setOnToast,
              onToast,
              setOnDesignList,
              setLimit,
              setPage,
              onDetail,
              setOnDetail,
              download,
              currentHouse,

              totalList,
              orderBy,
              orderHandler,
              disignList,
              viewDesign,
              houseType,
              TableSubTitle,
              TableItemContent,
              page,
              type5,
              landInfo,
              setHouseSelected,
              areaType,
              setAreaType,
              setLandPyeong,
            )}
          </div>
        ) : (
          <div
            className="fixed w-100per bg-00000050 "
            style={{ top: 0, bottom: 0, left: '449px', right: 0, zIndex: 100 }}
          >
            <div
              className="bg-FFFFFF"
              style={{
                width: '360px',
                height: `${window.innerHeight - 120}px`,
              }}
            >
              {newFunction2(
                setSelectedStdBuildingToLandRatio,
                setSelectedPyeong,
                setSelectedDesignFloor,
                setSelectedDesignIdx,
                setMinPyeongValue,
                setOnToast,
                onToast,
                setOnDesignList,
                setLimit,
                setPage,
                onDetail,
                setOnDetail,
                download,
                currentHouse,
                totalList,
                orderBy,
                orderHandler,
                disignList,
                viewDesign,
                houseType,
                TableSubTitle,
                TableItemContent,
                page,
                type5,
                landInfo,
                setHouseSelected,
                areaType,
                setAreaType,
                setLandPyeong,
              )}
              <div
                className={`${
                  window.innerWidth ? 'fixed' : ''
                } w-100per padding-16`}
                style={{
                  zIndex: 101,
                  bottom: 0,
                  background: window.innerWidth < 1024 ? '' : '#FFFFFF',
                }}
              >
                <Button
                  onClick={() => {
                    console.log('설계안 선택 클릭');
                    setOnToast(true);
                    setTimeout(() => {
                      setHouseSelected(true);
                      setOnDetail(false);
                      setOnDesignList(false);
                      setSelectedDesignFloor(currentHouse.floor);
                      setSelectedDesignIdx(currentHouse.designIdx);
                      setSelectedPyeong(
                        Math.round(
                          Number(currentHouse.totalArea * 0.3025) * 10,
                        ) / 10,
                      );
                      setSelectedStdBuildingToLandRatio(
                        currentHouse.stdBuildingToLandRatio,
                      );
                      // setMinPyeongValue(
                      //   type5 === "R"
                      //     ? Math.round(
                      //         Number(currentHouse.totalArea * 0.3025) * 10
                      //       ) / 10
                      //     : landInfo.housePyeong
                      // );

                      setPyeongValue(
                        type5 === 'N'
                          ? Math.round(
                              Number(currentHouse.totalArea * 0.3025) * 10,
                            ) / 10
                          : Math.round(
                              Number(landInfo.houseArea * 0.3025) * 10,
                            ) / 10,
                      );
                    }, 1300);
                  }}
                >
                  설계안 선택
                </Button>
              </div>
            </div>
          </div>
        )
      ) : (
        ''
      )}

      <div className="flex-row space-between">
        <TableTitle title={'1. 해당 주택에서 건축 가능한 설계안'} />
      </div>

      <div className="flex-column gap-40">
        <div className="flex-column w-100per gap-16">
          <div className="flex-row space-between center-y">
            <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
              {totalList}건 설계안
            </div>
            <div className="flex-row gap-4">
              <div
                className="flex-row gap-7 border-radius-100  padding-6-10 hover"
                style={{
                  border:
                    orderBy === 'PA' || orderBy === 'PD'
                      ? '1px solid #1e9b6b'
                      : '1px solid #171e2610',
                }}
                onClick={() => {
                  orderHandler('P');
                }}
              >
                <div
                  className="font-medium f-size-12 line-h-14 color-text-primary"
                  style={{
                    color:
                      orderBy === 'PA' || orderBy === 'PD'
                        ? '#1e9b6b'
                        : '#171e26',
                  }}
                >
                  평수
                </div>
                <div
                  className="font-medium f-size-12 line-h-14 color-text-primary"
                  style={{
                    color:
                      orderBy === 'PA' || orderBy === 'PD'
                        ? '#1e9b6b'
                        : '#171e26',
                  }}
                >
                  {orderBy === 'PD' ? '▲' : '▼'}
                </div>
              </div>
              <div
                className="flex-row gap-7 border-radius-100 border-1-171E26 padding-6-10 hover"
                style={{
                  border:
                    orderBy === 'FA' || orderBy === 'FD'
                      ? '1px solid #1e9b6b'
                      : '1px solid #171e2610',
                }}
                onClick={() => {
                  orderHandler('F');
                }}
              >
                <div
                  className="font-medium f-size-12 line-h-14 color-text-primary"
                  style={{
                    color:
                      orderBy === 'FA' || orderBy === 'FD'
                        ? '#1e9b6b'
                        : '#171e26',
                  }}
                >
                  층수
                </div>
                <div
                  className="font-medium f-size-12 line-h-14 color-text-primary"
                  style={{
                    color:
                      orderBy === 'FA' || orderBy === 'FD'
                        ? '#1e9b6b'
                        : '#171e26',
                  }}
                >
                  {orderBy === 'FD' ? '▲' : '▼'}
                </div>
              </div>
              {/* <div className="flex-row gap-7 border-radius-100 border-1-171E26 padding-6-10">
                  <div className="font-medium f-size-12 line-h-14 color-text-primary">
                    좋아요
                  </div>
                  <div className="font-medium f-size-12 line-h-14 color-text-primary">
                    v
                  </div>
                </div> */}
            </div>
          </div>
          {disignList.map((el: any, i: any) => {
            if (i > 2) {
              return;
            }
            return (
              <div
                className="flex-row gap-32 border-b-171E26 padding-b-16 hover"
                onClick={() => {
                  viewDesign(el.designIdx);
                }}
              >
                <div className="flex-row gap-2">
                  <div
                    className="font-bold f-size-14 line-h-20 color-text-primary"
                    style={{ width: '16px' }}
                  >
                    {i + 1}
                  </div>
                  <div
                    style={{ width: '117px', height: '88px' }}
                    className="border-radius-6"
                  >
                    <img
                      src={`https://back.transfarmer.kr/img/${el.mainImg}`}
                      alt=""
                      className="w-100per"
                    />
                  </div>
                </div>

                <div className="flex-column gap-4">
                  <div className="flex-row space-between  w-100per gap-10">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary no-wrap">
                      {el.designName}
                    </div>
                    {/* <img src={HeartIcon} alt="" /> */}
                  </div>
                  <div className="flex-column">
                    {[
                      {
                        title: '면적',
                        content:
                          type5 === 'R'
                            ? areaType === 'P'
                              ? `${landInfo.housePyeong}평`
                              : `${landInfo.housePyeong * 3.30579}m²`
                            : areaType === 'P'
                              ? `${Math.round(el.pyeong * 10) / 10}평`
                              : `${
                                  Math.round(el.pyeong * 10 * 3.30579) / 10
                                }m²`,
                      },
                      {
                        title: '층수',
                        content: el.floor ? `지상 ${el.floor}층` : '지상 2층',
                      },
                      {
                        title: '주택유형',
                        content: houseType[el.structure as 'A'] || '철골조',
                      },
                    ].map((el) => (
                      <div
                        className="flex-row w-100per gap-8 center-y"
                        style={{ width: 'fit-content' }}
                      >
                        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                          •
                        </div>
                        <TableSubTitle title={el.title} />
                        <TableItemContent content={el.content} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="flex-row center-x w-100per">
            <div
              className="border-radius-8 border-1-171E26 padding-8-16 w-fit-content hover"
              onClick={() => {
                setOnDesignList(true);
                setLimit(10);
              }}
            >
              <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                전체보기
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HousePrice;

function newFunction(
  setOnDesignList: any,
  setLimit: any,
  setPage: any,
  onDetail: any,
  setOnDetail: any,
  download: any,
  currentHouse: {
    authFile: string;
    buildingArea: number;
    buildingToLandRatio: number;
    drawingFile: null;
    floor: number;
    floorAreaRatio: number;
    floorPlan: string;
    landPyeong: number;
    mainImg: string;
    readingFile: string;
    structure: string;
    subImg1: string;
    subImg2: string;
    designName: string;
    designIdx: number;
    totalArea: number;
  },

  totalList: number,
  orderBy: string,
  orderHandler: (str: string) => void,
  disignList: {}[],
  viewDesign: (designIdx: string) => Promise<void>,
  houseType: { A: string; B: string; C: string; D: string; E: string },
  TableSubTitle: any,
  TableItemContent: any,
  page: number,
  type5: any,
  landInfo: any,
  areaType: any,
  setAreaType: any,
) {
  return (
    <div
      style={{
        position: 'fixed',
        maxWidth: '720px',
        left: '50%', // 중앙 정렬을 위해 왼쪽 50%
        transform: 'translateX(-50%)', // 요소의 너비의 절반만큼 왼쪽으로 이동
        bottom: 0,
        zIndex: 300,
        backgroundColor: '#FFFFFF',
        width: '100%',
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: '40px',
      }}
    >
      <div
        onClick={() => {
          setOnDesignList(false);
          setLimit(3);
          setPage(0);
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          fontFamily: 'Pretendard-Medium',
          fontSize: '14px',
          padding: '8px 16px',
          cursor: 'pointer',
          borderBottom: '1px solid rgba(23, 30, 38, 0.10)',
        }}
      >
        <div style={{ flex: 1, textAlign: 'center' }}>건축 설계안 전체보기</div>
        <img src="/asset/images/close/close-black.svg" alt="" />
      </div>
      <div className="padding-16 flex-row space-between">
        <div className="font-bold f-size-16 line-h-24 color-text-primary">
          AI 건축 설계안을 선택해 주세요
        </div>
        <div
          className="padding-2-8 flex-row border-1-171E26 border-radius-100 hover"
          onClick={() => {
            setAreaType((prev: any) => (prev === 'P' ? 'M' : 'P'));
          }}
        >
          <img src={Refresh} alt="" />
          <div className="font-medium f-size-12 line-h-20 color-text-tertiary">
            {areaType === 'P' ? 'm²' : '평'}
          </div>
        </div>
      </div>
      <div
        className="flex-column w-100per gap-16 padding-x-16"
        style={
          window.innerWidth
            ? {}
            : { height: '100%', overflowY: 'auto', paddingBottom: '150px' }
        }
      >
        <div className="flex-row space-between center-y">
          <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
            {totalList}건 설계안
          </div>
          <div className="flex-row gap-4">
            <div
              className="flex-row gap-7 border-radius-100  padding-6-10 hover"
              style={{
                border:
                  orderBy === 'PA' || orderBy === 'PD'
                    ? '1px solid #1e9b6b'
                    : '1px solid #171e2610',
              }}
              onClick={() => {
                orderHandler('P');
              }}
            >
              <div
                className="font-medium f-size-12 line-h-14 color-text-primary"
                style={{
                  color:
                    orderBy === 'PA' || orderBy === 'PD'
                      ? '#1e9b6b'
                      : '#171e26',
                }}
              >
                평수
              </div>
              <div
                className="font-medium f-size-12 line-h-14 color-text-primary"
                style={{
                  color:
                    orderBy === 'PA' || orderBy === 'PD'
                      ? '#1e9b6b'
                      : '#171e26',
                }}
              >
                {orderBy === 'PD' ? '▲' : ' ▼'}
              </div>
            </div>
            <div
              className="flex-row gap-7 border-radius-100 border-1-171E26 padding-6-10 hover"
              style={{
                border:
                  orderBy === 'FA' || orderBy === 'FD'
                    ? '1px solid #1e9b6b'
                    : '1px solid #171e2610',
              }}
              onClick={() => {
                orderHandler('F');
              }}
            >
              <div
                className="font-medium f-size-12 line-h-14 color-text-primary"
                style={{
                  color:
                    orderBy === 'FA' || orderBy === 'FD'
                      ? '#1e9b6b'
                      : '#171e26',
                }}
              >
                층수
              </div>
              <div
                className="font-medium f-size-12 line-h-14 color-text-primary"
                style={{
                  color:
                    orderBy === 'FA' || orderBy === 'FD'
                      ? '#1e9b6b'
                      : '#171e26',
                }}
              >
                {orderBy === 'FD' ? '▲' : '▼'}
              </div>
            </div>
            {/* <div className="flex-row gap-7 border-radius-100 border-1-171E26 padding-6-10">
          <div className="font-medium f-size-12 line-h-14 color-text-primary">
            좋아요
          </div>
          <div className="font-medium f-size-12 line-h-14 color-text-primary">
            v
          </div>
        </div> */}
          </div>
        </div>
        {disignList.map((el: any, i: any) => (
          <div
            className="flex-row gap-32 border-b-171E26  padding-x-16 padding-b-16 hover"
            onClick={() => {
              viewDesign(el.designIdx);
            }}
          >
            <div className="flex-row gap-2 ">
              <div
                style={{ width: '117px', height: '88px' }}
                className="border-radius-6"
              >
                <img
                  src={`https://back.transfarmer.kr/img/${el.mainImg}`}
                  alt=""
                  className="w-100per"
                />
              </div>
            </div>

            <div className="flex-column gap-4">
              <div className="flex-row space-between w-100per gap-10">
                <div className="font-bold f-size-14 line-h-20 color-text-primary no-wrap">
                  {el.designName}
                </div>
                <img src={HeartIcon} alt="" />
              </div>
              <div className="flex-column">
                {[
                  {
                    title: '면적',
                    content:
                      type5 === 'R'
                        ? areaType === 'P'
                          ? `${landInfo.housePyeong}평`
                          : `${landInfo.housePyeong * 3.30579}m²`
                        : areaType === 'P'
                          ? `${Math.round(el.pyeong * 10) / 10}평`
                          : `${Math.round(el.pyeong * 10 * 3.30579) / 10}m²`,
                  },
                  {
                    title: '층수',
                    content: el.floor ? `지상 ${el.floor}층` : '지상 2층',
                  },
                  {
                    title: '주택유형',
                    content: houseType[el.structure as 'A'] || '철골조',
                  },
                ].map((el) => (
                  <div
                    className="flex-row w-100per gap-8 center-y"
                    style={{ width: 'fit-content' }}
                  >
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      •
                    </div>
                    <TableSubTitle title={el.title} />
                    <TableItemContent content={el.content} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        {totalList > disignList.length && (
          <div
            className="flex-row center-x w-100per"
            // style={{ paddingBottom: (window.innerWidth) ? '' : '20px' }}
          >
            <div
              className="border-radius-8 border-1-171E26 padding-8-16 w-fit-content hover"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                더보기
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
function newFunction2(
  setSelectedStdBuildingToLandRatio: any,
  setSelectedPyeong: any,
  setSelectedDesignFloor: any,
  setSelectedDesignIdx: any,
  setMinPyeongValue: any,
  setOnToast: any,
  onToast: any,
  setOnDesignList: any,
  setLimit: any,
  setPage: any,
  onDetail: any,
  setOnDetail: any,
  download: any,
  currentHouse: {
    stdBuildingToLandRatio: number;
    pyeong: number;
    authFile: string;
    buildingArea: number;
    buildingToLandRatio: number;
    drawingFile: null;
    floor: number;
    floorAreaRatio: number;
    floorPlan: string;
    landPyeong: number;
    mainImg: string;
    readingFile: string;
    structure: string;
    subImg1: string;
    subImg2: string;
    designName: string;
    designIdx: number;
    totalArea: number;
  },
  totalList: number,
  orderBy: string,
  orderHandler: (str: string) => void,
  disignList: {}[],
  viewDesign: (designIdx: string) => Promise<void>,
  houseType: { A: string; B: string; C: string; D: string; E: string },
  TableSubTitle: any,
  TableItemContent: any,
  page: number,
  type5: any,
  landInfo: any,
  setHouseSelected: any,
  areaType: any,
  setAreaType: any,
  setLandPyeong: any,
) {
  console.log('newFunction2');
  return (
    <div
      className={`bg-00000050 w-100per `}
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        // left: 0,
        zIndex: 320,
        paddingTop: '48px',
        height:
          window.innerWidth < 1024 ? 'auto' : `${window.innerHeight - 85}px`,
        background: window.innerWidth < 1024 ? '' : '#FFFFFF',
        maxWidth: '720px',
        left: '50%', // 중앙 정렬을 위해 왼쪽 50%
        transform: 'translateX(-50%)', // 요소의 너비의 절반만큼 왼쪽으로 이동
      }}
    >
      <div className="bg-FFFFFF border-radius-t-l-16 border-radius-t-r-16 h-100per">
        <div className="padding-16 flex-row space-between border-b-171E26">
          <div className="font-bold f-size-16 line-h-24 color-text-primary">
            농촌 주택
          </div>
          <div className="flex-row gap-5">
            <div
              className="padding-2-8 flex-row border-1-171E26 border-radius-100 hover"
              onClick={() =>
                setAreaType((prev: any) => (prev === 'P' ? 'M' : 'P'))
              }
            >
              <div></div>
              <img src={Refresh} alt="" />
              <div className="font-medium f-size-12 line-h-20 color-text-tertiary">
                {areaType === 'P' ? 'm²' : '평'}
              </div>
            </div>
            <div className="hover" onClick={() => setOnDetail(false)}>
              ⨉
            </div>
            <img
              className="hover"
              onClick={() => setOnDetail(false)}
              src={Close}
              alt=""
            />
          </div>
        </div>
        <div
          className="padding-16 flex-column gap-16 of-y-auto"
          style={{
            paddingBottom: window.innerWidth < 1024 ? '150px' : '108px',
            height:
              window.innerWidth < 1024
                ? window.innerHeight - 200 + 'px'
                : `${window.innerHeight - 142}px`,
          }}
        >
          <div
            className=" flex-row w-100per gap-8"
            style={{ flexWrap: 'wrap' }}
          >
            {[
              {
                title: '토지면적',
                content:
                  areaType === 'P'
                    ? `${currentHouse.landPyeong} 평`
                    : `${
                        Math.round(currentHouse.landPyeong * 3.306 * 10) / 10
                      } m²`,
              },
              {
                title: '용적률',
                content:
                  `${
                    Math.round(
                      ((Number(currentHouse.totalArea) * 0.3025) /
                        Number(currentHouse.landPyeong)) *
                        100 *
                        10,
                    ) / 10
                  }%` || '11.8평',
              },
              /*용적률 = 연면적 / 토지면적 * 100
건폐율 = 1층면적 / 토지면적 * 100*/
              {
                title: '연면적',
                content:
                  type5 === 'R'
                    ? areaType === 'P'
                      ? `${
                          Math.round(Number(landInfo.houseArea * 0.3025) * 10) /
                          10
                        }평`
                      : `${Math.round(Number(landInfo.houseArea) * 10) / 10}m²`
                    : areaType === 'P'
                      ? `${
                          Math.round(
                            Number(currentHouse.totalArea * 0.3025) * 10,
                          ) / 10
                        }평`
                      : `${
                          Math.round(Number(currentHouse.totalArea) * 10) / 10
                        }m²`,
              },
              {
                title: '건폐율',
                content:
                  `${
                    Math.round(
                      ((Number(currentHouse.totalArea) * 0.3025) /
                        currentHouse.floor /
                        Number(currentHouse.landPyeong)) *
                        100 *
                        10,
                    ) / 10
                  }%` || '20% (법정 60% 이하)',
              },
              {
                title: '주택유형',
                content:
                  houseType[currentHouse.structure as 'A'] ||
                  '20% (법정 60% 이하)',
              },
              {
                title: '층수',
                content:
                  `지상 ${currentHouse.floor}층` || '20% (법정 60% 이하)',
              },
            ].map((el) => (
              <div
                className="flex-row w-100per gap-8 center-y flex-1"
                style={{ width: 'fit-content' }}
              >
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  •
                </div>
                <TableSubTitle title={el.title} />
                <TableItemContent content={el.content} />
              </div>
            ))}
          </div>
          <img
            className="w-100per"
            src={`https://back.transfarmer.kr/img/${currentHouse.mainImg}`}
            alt=""
          />
          <img
            className="w-100per"
            src={`https://back.transfarmer.kr/img/${currentHouse.subImg1}`}
            alt=""
          />
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            • 지상 1층 평면도
          </div>
          <img
            className="w-100per"
            src={`https://back.transfarmer.kr/img/${currentHouse.floorPlan}`}
            alt=""
          />
          <div
            className=" flex-row w-100per gap-8"
            style={{ flexWrap: 'wrap' }}
          >
            {[
              {
                title: '열람용',
                value: currentHouse.readingFile,
                content:
                  `${
                    currentHouse.readingFile &&
                    currentHouse.readingFile.split('/')[3]
                  }` || '지상 2층',
              },
              {
                title: '인허가용',
                value: currentHouse.authFile,
                content:
                  `${
                    currentHouse.authFile && currentHouse.authFile.split('/')[3]
                  }` || '11.8평',
              },
              {
                title: '3D도면',
                value: currentHouse.drawingFile,
                content:
                  `${
                    currentHouse.drawingFile !== null &&
                    (currentHouse.drawingFile as '').split('/')[3]
                  }` || '20% (법정 200% 이하)',
              },
            ].map((el) => {
              if (el.value === null) {
                return;
              }
              return (
                <div
                  className="flex-row w-100per gap-8 center-y hover"
                  onClick={() => {
                    download(el.value);
                  }}
                  style={{ width: 'fit-content' }}
                >
                  <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                    •
                  </div>
                  <TableSubTitle title={el.title} />
                  <TableItemContent
                    content={el.content !== '' ? el.content : '-'}
                  />
                </div>
              );
            })}
          </div>

          <div className="padding-16 flex-column bg-blue-gray-100 border-radius-8">
            <div
              className=" flex-column w-100per gap-4"
              style={{ flexWrap: 'wrap' }}
            >
              {[
                {
                  content:
                    '본 농촌주택 설계자료는 농림축산식품부, 한국농어촌공사의 도면유형 [2022]농림-22-37-가 자료임을 밝힙니다.',
                },
                {
                  content: '실제 시공업체와 견적 시, 수정될 수 있습니다.',
                },
              ].map((el) => (
                <div
                  className="flex-row w-100per gap-8 center-y"
                  style={{ width: 'fit-content' }}
                >
                  <div className="font-medium f-size-11 line-h-16 color-text-tertiary">
                    • {el.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`${
            window.innerWidth < 1024 ? 'fixed' : ''
          } w-100per padding-16`}
          style={{
            position: window.innerWidth < 1024 ? 'fixed' : 'relative',
            zIndex: 100,
            bottom: window.innerWidth < 1024 ? 0 : 51,
            // visibility: window.innerWidth < 1024 ? 'visible' : 'hidden',
            background: window.innerWidth < 1024 ? '' : '#FFFFFF',
          }}
        >
          {/* <Button
            on
            description='설계안 선택'
            onClick={() => {
              setOnToast(true);
              setTimeout(() => {
                setHouseSelected(true);
                setOnDetail(false);
                setOnDesignList(false);
                setSelectedDesignFloor(currentHouse.floor);
                setSelectedDesignIdx(currentHouse.designIdx);
                setSelectedPyeong(
                  Math.round(Number(currentHouse.totalArea * 0.3025) * 10) / 10
                );
                setSelectedStdBuildingToLandRatio(
                  currentHouse.stdBuildingToLandRatio
                );
                // setMinPyeongValue(
                //   type5 === "R"
                //     ? Math.round(Number(currentHouse.totalArea * 0.3025) * 10) /
                //         10
                //     : landInfo.housePyeong
                // );
              }, 1300);
            }}
          ></Button> */}
          <Button
            onClick={() => {
              console.log('설계안 선택 클릭');
              setOnToast(true);
              setHouseSelected(true);
              setOnDetail(false);
              setOnDesignList(false);
              setSelectedDesignFloor(currentHouse.floor);
              setSelectedDesignIdx(currentHouse.designIdx);
              setSelectedPyeong(
                Math.round(Number(currentHouse.totalArea * 0.3025) * 10) / 10,
              );
              setSelectedStdBuildingToLandRatio(
                currentHouse.stdBuildingToLandRatio,
              );
              setLandPyeong(Math.round(Number(currentHouse.landPyeong)));
              setMinPyeongValue(
                type5 === 'R'
                  ? Math.round(Number(currentHouse.totalArea * 0.3025) * 10) /
                      10
                  : landInfo.housePyeong,
              );
            }}
            style={{ cursor: 'pointer' }}
          >
            <ButtonText>설계안 선택</ButtonText>
          </Button>
        </div>
      </div>
    </div>
  );
}

const Button = styled.div`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #1e9b6b;
`;

const ButtonText = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;

const ToastModal = styled.div`
  position: fixed;
  display: flex;
  z-index: 100;
  bottom: 90px;
  width: calc(100% - 32px);
  max-width: 688px;
  background-color: rgba(81, 85, 86, 1);
  padding: 14px 16px;
  border-radius: 12px;
  gap: 8px;
  boxshadow: 0px 0px 1px rgba(0, 0, 0, 0.08);
  border: 1px #515556 solid;
  color: white;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;
